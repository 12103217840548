// CSS
import '../scss/main.scss';
import './components/categories';

(function() {

  "use strict";

  var toggles = document.querySelectorAll(".cmn-toggle-switch");

  for (var i = toggles.length - 1; i >= 0; i--) {
    var toggle = toggles[i];
    toggleHandler(toggle);
  };

  function toggleHandler(toggle) {
    toggle.addEventListener( "click", function(e) {
      e.preventDefault();
      (this.classList.contains("active") === true) ? this.classList.remove("active") : this.classList.add("active");
    });
  }

})();

jQuery('document').ready(function($){

    // Mobile menu
    $('.mobile-container .navbar-container .nav').hide();
    $('.navbar-toggler').click(function(){
        $('.navbar-container .nav').slideToggle();
    });

    // Blog anchor class
    $('.blog-feed a.read-more').addClass('button');

    // Membership section
    $('.single-membership-inner-container .information-container').hide();
    $('.single-membership-inner-container .modal-button').click( function(){
         $(this).next('.information-container').first().slideToggle();
    });

    // Image text element
    $('.page-what-we-do .image-text .col-lg-6').addClass('offset-lg-6');
    $('.page-our-members .image-text .col-lg-6').addClass('offset-lg-6');
    $('.blog .image-text .col-lg-6').addClass('offset-lg-6');
    $('.archive .image-text .col-lg-6').addClass('offset-lg-6');

});
