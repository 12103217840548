import $ from 'jquery';

$(() => {

	// Go to selected category page from categories dropdown
	$('#js-categories-dropdown').change(event => {
		const $dropdown = $(event.currentTarget);

		window.location.href = $dropdown.val();
	});

});
